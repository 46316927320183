import React, { useState, useMemo, useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useNavigate,
    useLocation,
} from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/success" element={<Success />} />
                <Route path="/demographics" element={<Demographics />} />
                <Route
                    path="/failed-attention-check"
                    element={<FailedAttentionCheck />}
                />
            </Routes>
        </Router>
    );
}

function FailedAttentionCheck() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>You Failed the Attention Check</h1>
                <p>
                    Your account has been deleted due to mislabeling the dummy
                    video.
                </p>
            </header>
        </div>
    );
}

function Home() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = () => {
        setLoading(true);
        fetch(
            `https://tiktok-misinformation.ezrajohnson.me/assign-videos?username=${username}&password=${password}`,
            {
                method: "GET",
            }
        )
            .then((response) => {
                setLoading(false);
                if (!response.ok) {
                    if (response.status === 401) {
                        throw new Error("Incorrect username or password");
                    } else if (response.status === 404) {
                        throw new Error("No available videos to assign");
                    } else {
                        throw new Error("An unexpected error occurred");
                    }
                }
                return response.json();
            })
            .then((data) => {
                setLoading(false);

                // Navigate based on the success field
                if (data.success) {
                    navigate("/success"); // Go to the Success page
                } else {
                    navigate("/welcome", {
                        state: { videos: data.videos, username },
                    }); // Pass videos and username to the Welcome page
                }
            })
            .catch((error) => {
                setLoading(false);
                alert(error.message);
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            });
    };

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>Enter your username and password to access your videos.</p>
                <div>
                    <input
                        type="text"
                        placeholder="Enter your username"
                        value={username}
                        onChange={handleUsernameChange}
                        className="username-input"
                    />
                    <input
                        type="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={handlePasswordChange}
                        className="password-input"
                    />
                    <button
                        onClick={handleSubmit}
                        className="submit-button"
                        disabled={loading}
                    >
                        {loading ? "Loading..." : "Submit"}
                    </button>
                </div>
            </header>
        </div>
    );
}

function Welcome() {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state || { videos: [], username: "" };

    const dummyVideo = "1mJGs5dLIZ2YPCZElbSsEWcjDAYvSX6IC";
    const [labeledDummyVideo, setLabeledDummyVideo] = useState(false);
    const videos = useMemo(() => [dummyVideo, ...state.videos], [state.videos]);
    const username = state.username;
    const [startTime, setStartTime] = useState(Date.now());

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [labels, setLabels] = useState(Array(videos.length).fill(null));
    const [explanations, setExplanations] = useState(
        Array(videos.length).fill("")
    );
    const [videoWatched, setVideoWatched] = useState(false);

    const VIDEO_DURATION_MS = 15000; // Constant 15 seconds

    useEffect(() => {
        setStartTime(Date.now());
    }, [currentVideoIndex]);

    const handleLabelChange = (value) => {
        // If the dummy video is labeled as something other than "misinformative", set the flag
        if (
            videos[currentVideoIndex] === dummyVideo &&
            value !== "misinformative"
        ) {
            setLabeledDummyVideo(true);
        } else {
            setLabeledDummyVideo(false);
        }
        const newLabels = [...labels];
        newLabels[currentVideoIndex] = value;
        setLabels(newLabels);
    };

    const handleExplanationChange = (event) => {
        const newExplanations = [...explanations];
        newExplanations[currentVideoIndex] = event.target.value;
        setExplanations(newExplanations);
    };

    const handleNextVideo = () => {
        if (labels[currentVideoIndex] === null) {
            alert("Please label the current video before proceeding.");
            return;
        }
        if (explanations[currentVideoIndex].trim() === "") {
            alert("Please provide an explanation before proceeding.");
            return;
        }

        // If the dummy video was labeled wrongly, send a request to delete the account
        if (labeledDummyVideo) {
            fetch(
                "https://tiktok-misinformation.ezrajohnson.me/delete-account",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ username }),
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Error deleting account");
                    }
                    console.log("Account deleted successfully.");
                    navigate("/failed-attention-check"); // Navigate to the failure page
                })
                .catch((error) => {
                    alert("Error deleting account: " + error.message);
                    console.error(error);
                });
        }

        setVideoWatched(false);
        setCurrentVideoIndex((prevIndex) => prevIndex + 1);
        setStartTime(Date.now());
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (Date.now() - startTime >= VIDEO_DURATION_MS && !videoWatched) {
                setVideoWatched(true);
            }
        }, 1000); // Check every second

        return () => clearInterval(timer);
    }, [startTime, videoWatched]);

    const handleSubmitLabels = () => {
        if (
            labels.some((label) => label === null) ||
            explanations.some((explanation) => explanation.trim() === "")
        ) {
            alert(
                "Please label all videos and provide explanations before submitting."
            );
            return;
        }

        const labeledVideos = videos.map((video, index) => ({
            videoId: video,
            label: labels[index],
            explanation: explanations[index],
        }));

        fetch("https://tiktok-misinformation.ezrajohnson.me/submit-labels", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ username, labels: labeledVideos }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                console.log("Labels submitted successfully:", data);
                navigate("/demographics", { state: { username } });
            })
            .catch((error) => {
                alert(
                    "There was an error submitting your labels, please try again."
                );
                console.error(
                    "There was a problem with the fetch operation:",
                    error
                );
            });
    };

    if (currentVideoIndex >= videos.length) {
        return (
            <div className="App">
                <header className="App-header">
                    <h1>All videos have been reviewed!</h1>
                    <button onClick={handleSubmitLabels}>
                        Submit All Labels
                    </button>
                </header>
            </div>
        );
    }

    return (
        <div className="App">
            <header className="App-header">
                <h1>
                    Video {currentVideoIndex + 1} of {videos.length}
                </h1>
                <iframe
                    id={`video-${currentVideoIndex}`}
                    src={`https://drive.google.com/file/d/${videos[currentVideoIndex]}/preview`}
                    width="640"
                    height="480"
                    title={`video-${currentVideoIndex}`}
                    allow="autoplay"
                ></iframe>
                <div>
                    {videoWatched ? (
                        <p>You can now submit your labels for the video.</p>
                    ) : (
                        <p>
                            Please watch the full video before submitting your
                            label. You may submit after at least 15 seconds if
                            necessary.
                        </p>
                    )}
                    <label>
                        <input
                            type="radio"
                            name={`label-${currentVideoIndex}`}
                            value="truthful"
                            checked={labels[currentVideoIndex] === "truthful"}
                            onChange={() => handleLabelChange("truthful")}
                        />
                        Non-misinformative about climate change
                    </label>
                    <label>
                        <input
                            type="radio"
                            name={`label-${currentVideoIndex}`}
                            value="misinformative"
                            checked={
                                labels[currentVideoIndex] === "misinformative"
                            }
                            onChange={() => handleLabelChange("misinformative")}
                        />
                        Misinformative about climate change
                    </label>
                    <label>
                        <input
                            type="radio"
                            name={`label-${currentVideoIndex}`}
                            value="other_language"
                            checked={
                                labels[currentVideoIndex] === "other_language"
                            }
                            onChange={() => handleLabelChange("other_language")}
                        />
                        Non-English Language
                    </label>
                </div>
                <div>
                    <textarea
                        placeholder="Explain your reasoning (100 characters or less)"
                        maxLength={100}
                        value={explanations[currentVideoIndex]}
                        onChange={handleExplanationChange}
                    ></textarea>
                </div>
                <button
                    onClick={handleNextVideo}
                    disabled={
                        !videoWatched ||
                        labels[currentVideoIndex] === null ||
                        explanations[currentVideoIndex].trim() === ""
                    }
                >
                    Next Video
                </button>
            </header>
        </div>
    );
}

function Success() {
    return (
        <div className="App">
            <header className="App-header">
                <h1>Thank you for your submissions!</h1>
                <p>
                    Your contributions have been recorded. Here’s your credit
                    code: C1CIIY19.
                </p>
            </header>
        </div>
    );
}

function Demographics() {
    const [gender, setGender] = useState("");
    const [age, setAge] = useState("");
    const [politicalLeaning, setPoliticalLeaning] = useState("");
    const navigate = useNavigate();

    const location = useLocation(); // Get location object
    const { username } = location.state || {};

    const handleSubmit = async () => {
        if (!gender || !age || !politicalLeaning) {
            alert("All fields are required.");
            return;
        }

        try {
            const response = await fetch(
                "https://tiktok-misinformation.ezrajohnson.me/upload-demographics", // Your backend endpoint
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username, // Include the username from the state
                        gender,
                        age,
                        politicalLeaning,
                    }),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(
                    errorData.error || "Failed to submit demographics."
                );
            }

            alert("Demographic information submitted successfully.");
            navigate("/success"); // Redirect to the success page
        } catch (error) {
            console.error("Error submitting demographics:", error);
            alert("An error occurred while submitting demographics.");
        }
    };

    return (
        <div className="App">
            <header className="App-header">
                <h1>Demographics Information</h1>
                <form>
                    <label>
                        Gender:
                        <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                        >
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Non-Binary">Non-Binary</option>
                            <option value="Other">Other</option>
                        </select>
                    </label>
                    <label>
                        Age:
                        <input
                            type="number"
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                        />
                    </label>
                    <label>
                        Political Leaning:
                        <select
                            value={politicalLeaning}
                            onChange={(e) =>
                                setPoliticalLeaning(e.target.value)
                            }
                        >
                            <option value="">Select</option>
                            <option value="Liberal">Liberal</option>
                            <option value="Moderate">Moderate</option>
                            <option value="Conservative">Conservative</option>
                        </select>
                    </label>
                    <button type="button" onClick={handleSubmit}>
                        Submit
                    </button>
                </form>
            </header>
        </div>
    );
}

export default App;
